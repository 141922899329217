<template>
    <div class="grid">  
		<div class="col-12"> 
			<div class="card"> 
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start> 
                        <h3> {{module_name}}</h3>  
                    </template>
				</Toolbar> 
                
                <Toolbar class="pl-0 pr-0">
					<template v-slot:start> 
                        <Button  v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" /> 
                        <!-- <Button  v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSV($event)" /> -->
                        <!-- <Button  v-tooltip.top="'Remove Filters'" type="button" icon="pi pi-filter-slash" :label="showBtnLabels ? 'Remove Filters' : ''" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  @click="clearFilter()"/>  -->
                        
					</template> 
                   <!-- <template v-slot:end> 
                        <Button
                            @click="addNewRecord"
                            v-tooltip.top="'Add New HO/Crops'"
                            type="button"
                            icon="pi pi-plus"
                            :label="showBtnLabels ? 'Add New HO/Crops' : ''"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-info mr-2"
                        />
					</template>   -->
				</Toolbar> 
				<DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters" :loading="loading" :filters="filters" responsiveLayout="scroll"
					:globalFilterFields="['name','student_id','hostel','dob','cnic','father_name','email','mobile_1','class','roll_no','room_no','status']" :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" selectionMode="multiple"
                    :scrollable="true" scrollHeight="400px">
                    
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">  
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" ></i>
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                            </span>
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Select Columns" style="width: 20em"/>
                        </div>
                    </template>
                    
                    <template #empty> 
                        <div class="table-message"> 
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                        </div>
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template> 
                    
                    <!-- <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column> -->
                    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" 
                        :exportable="col.field == 'action' ? false : true" :sortable="col.field == 'action' ? false : true" 
                        :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction' : ''" 
                        :style="col.field !== 'action' ? 
                        'min-width:20rem;flex-grow:1; flex-basis:160px;'
                        :
                        'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 

                        <template  #body="{data}">
                            <img style="height:50px; width:50px;"  v-if="col.field == 'name' && data.profile_pic" :alt="data.name" :src="studentFiles+'students/'+data.id+'/'+data.profile_pic" class="tableProfileImg mr-2" />
                            <img style="height:50px; width:50px;"  v-if="col.field == 'name' && !data.profile_pic" :alt="data.name" :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" />
                            
                            <span v-if="col.field == 'name'" @click="selectedRecordId=data.id; getClickedRecord('view');">
                                <b>{{data.name}} ({{data.identifiable_id}})</b>
                                <br />{{ data.email }}<br />{{ data.phone }}
                            </span> 
                            
                            <!-- <span v-if="col.field == 'class'">
                                <b>Class: </b>{{ data.class }}<br />
                                <b>Roll No: </b>{{ data.roll_no }}
                            </span> -->
                            <span v-if="col.field == 'hostel'">
                                <b>Hostel: </b>{{ data.hostel }}<br />
                                <b>Room No: </b>{{ data.room_no }}
                            </span>
                            <span v-if="col.field == 'dob'">
                                <!-- <b>DOB: </b>{{ data.dob }}<br /> -->
                                <b>CNIC: </b>{{ data.cnic }}<br />
                                <b>Gender: </b>{{ data.gender }}
                            </span>
                            <!-- <span v-if="col.field == 'email'">{{ data.email }}</span> -->
                            <!-- <span v-if="col.field == 'cnic'">{{ data.cnic }}</span> -->
                            <!-- <span v-if="col.field == 'gender'">{{ data.gender }}</span> -->
                            <!-- <span v-if="col.field == 'mobile_1'">{{ data.mobile_1 }}</span> -->
                            <span v-if="col.field == 'father_name'">
                                <div v-if="data.parsedRelatives.length">
                                    <div v-for="(relative, rIndex) in data.parsedRelatives" :key="rIndex">
                                      <b>{{ capitalizeFirstLetter(relative.relation) }} Name: </b>{{ relative.rel_name }}<br />
                                      <b>{{ capitalizeFirstLetter(relative.relation) }} Mobile: </b>{{ relative.rel_mobile_no }}<br />
                                    </div>
                                  </div>
                                
                            </span>
                            
                            <!-- <span v-if="col.field == 'father_mobile_no'">{{ data.father_mobile_no }}</span> -->

                            <span v-if="col.field == 'status'"> 
                                <Tag :class="data.status == 'Active' ? 'mr-2 status-Active' : 'mr-2 status-Blocked'" icon="pi pi-check" :severity="data.status == 'Active' ? 'success' : 'danger'" :value="data.status"></Tag>
                            </span>
                            <span v-if="col.field == 'action'">
                                <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                </div> 
                            </span>
                        </template>
                        <template #filter="{filterModel}">
                            <span v-if="col.field == 'name'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                            </span>   
                           
                            <span v-else-if="col.field == 'status'">
                                <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                    <template #value="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                        <span v-else>{{slotProps.placeholder}}</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                    </template>
                                </Dropdown>
                            </span>  
                        </template>   
                    </Column>   
				</DataTable>
			</div>
		</div>
        <Toast />
        
        <StudentForm v-on:formClose="closeForm" v-if="showForm" :recordId="selectedRecordId" :showForm="true" /> 
        <StudentView v-on:formClose="closeForm" v-if="showView" :recordId="selectedRecordId" :showView="true" /> 
        <StudentAddEdit v-on:formClose="closeForm" v-if="showAddEdit" :recordId="selectedRecordId" :showAddEdit="true" /> 
    </div>
</template>
<script>
import StudentForm from '../../components/HoCore/HoCoreForm.vue';
import StudentView from '../../components/HoCore/HoCoreView.vue';
import StudentAddEdit from '../../components/HoCore/HoCoreAddEdit.vue';
import {FilterMatchMode,FilterOperator} from 'primevue/api';  
export default {
    data() {
			return {  
                module_name:'House Officers & Corps', 
                actions: [], 
                showHelp : false, 
                isSaving:false, 
				records: null, 
				filters: null, 
				loading: true,  
				selectedRecords: null,
				columns: null,
                selectedColumns: null, 
                showForm:false,
                showAddEdit:false,
                showView:false,
                selectedRecordId: 0,  
                saveAndClose:false,
                selectedData: '',
			}
		},  
        components:{
            StudentForm,
            StudentView,
        },
		created() { 
            this.actions.push({
                    label: 'View',
                    icon: 'pi pi-eye',
                    command: () => {
                        this.getClickedRecord('view');
                    }
                });
            this.actions.push({
                    label: 'Room No',
                    icon: 'pi pi-refresh',
                    command: () => {
                        this.getClickedRecord('roomNo');
                    }
                });
                
			this.initFilters();
            this.columns = [
                {field: 'action', header: 'Action'},
                {field: 'name', header: 'Name'},    
                //{field: 'class', header: 'Academic Info'},    
                {field: 'hostel', header: 'Hostel Info'},    
                {field: 'dob', header: 'Personal Info'},    
                {field: 'father_name', header: 'Family Info'},    
                {field: 'status', header: 'Status'}, 
               
            ];
            this.selectedColumns = this.columns;
		},
        computed: { 
        },
		mounted() { 
			this.getRecords();  
		},
		methods: {
            addNewRecord() {
                this.selectedData = null;
                this.selectedRecordId = 0;
                this.showAddEdit = true;
            },
           
            getClickedRecord(action){
                if(action == 'view'){
                    this.showView = true;
                }  
                else if(action == 'roomNo'){
                    this.showForm = true; 
                }
            },
            toggleRecordMenu(event) {
                this.selectedRecordId = event.currentTarget.getAttribute('recordId');
                this.selectedData = event.currentTarget.getAttribute('selectedData');  
				this.$refs.menu.toggle(event);
			},
            loadForm(){ 
                this.selectedRecordId = 0;
                this.showForm = true;
            },
            closeForm(){ 
                if(!this.selectedRecordId){
                    this.getRecords();
                }
                this.showForm = false;
                this.showView = false;
                this.showAddEdit = false;
            }, 
            getRecords() {
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllHosteliseHoCore')
                    .then((response) => {  
                    this.records = response.data.map(record => {
                        try {
                        record.parsedRelatives = JSON.parse(`[${record.relatives}]`);
                        } catch (e) {
                        console.error('Error parsing relatives JSON:', e);
                        record.parsedRelatives = [];
                        }
                        return record;
                    });
                    this.loading = false; 
                    })
                    .catch((error) => {    
                    let message = Vthis.customError(error.response); 
                    Vthis.showMessage(message, 'error');  
                    }).finally(() => { 
                    });
                }, 
           
            // exportCSV() {
            //     this.$refs.dt.exportCSV();
            // },
            onToggle(value) {
                this.selectedColumns = this.columns.filter(col => value.includes(col));
            },
            
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					// 'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					// 'hostel': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					// 'room_no': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					// 'cnic': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					// 'roll_no': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					// 'email': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
				}
			},
			clearFilter() {
				this.initFilters();
			}, 
            isRTL() {
				return this.$appState.RTL;
			},
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }
            
		},
        watch : { 
        }
}
</script>
 
